







import { Component, Vue } from 'vue-property-decorator';
import Main from './components/Main.vue';
import HeaderBar from './components/Header.vue';

@Component({
  components: {
    HeaderBar,
    Main,
  },
})
export default class App extends Vue {}
