























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Main extends Vue {
  projects: {
      name: string;
      description: string[];
      quote?: string;
    }[] = [
    {
      name: 'tridoc',
      quote: 'Tridoc aims to provide easy document management for individuals and small teams.',
      description: [
        'Documents can be accessed in the browser via a <a href="https://github.com/tridoc/tridoc-cockpit">web interface</a>.',
        'On the server-side, Tridoc runs using hapijs in node and fuseki. <a href="https://github.com/tridoc/tridoc-backend">View the repository of the backend.</a>',
        'There also exists <a href="https://github.com/tridoc/tridoc-folder">a native program</a> for easy upload from a folder on your computer. It doesn’t, however, provide any graphical user interface (yet?).',
        'Tridoc is mostly written and maintained by me.']
    },
    {
      name: 'SynoSpecies',
      quote: 'Synospecies is a tool developed by FactsMission AG to leverage the RDF data provided by <a href="https://plazi.org/">Plazi</a>. The RDF data of all treatments is stored in an <a href="https://allegrograph.com/">AllegroGraph</a> triple store allowing <a href="https://www.w3.org/TR/sparql11-overview/">SPARQL</a> queries over the data. Synospecies allows manually writing and submitting such queries in the advanced mode and send such queries in the background when using the easier interface.</blockquote>',
      description: [],
    }
  ]
}
